import React from 'react';
import './index.scss';

const SingleColumnBlock = (props) => {
  return (
    <div className={`text-block`}>
      <div className="wrapper">
        <div className={`content ${props.textPadded ? 'padded' : ''}`}>
          {props.text}
        </div>
      </div>
    </div>
  );
}

export default SingleColumnBlock;

SingleColumnBlock.defaultProps = {
  textPadded: false,
  text: '',
}
